import React, { Fragment } from "react";
import { observer } from 'mobx-react-lite';

import Product from '../components/Product';
import { useStore } from '../stores/hooks';
import IProduct from '../models/IProduct';

function getProduct(product?: IProduct) {
  if (product) return <Product product={product} />;
  return <Fragment>Loading...</Fragment>
}

export function ProductPage(props: any) {
  const { id } = props.match.params;

  const productStore = useStore("productStore");
  const product = productStore.products.find(p => p.id === id);

  return (
    <div
      className="welcome-container"
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <section className="section">
        <div className="container">
          {getProduct(product)}
        </div>
      </section>
    </div>
  );
}

export default observer(ProductPage);