import { makeAutoObservable } from "mobx";
import ICartProduct from '../models/ICartProduct';
import IProduct from '../models/IProduct';

export class CartStore {
  cart: ICartProduct[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  addToCart(product: IProduct, quantity: number, imageUrls: string) {
    const cartProduct: ICartProduct = {
      ...product,
      quantity,
      imageUrls,
    }

    this.cart.push(cartProduct);
  }
}