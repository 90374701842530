import React from 'react';

export default function Gallery() {
  return (
    <div className="welcome-container">
      <section className="section">
        <div className="container">
          <h1 className="title has-text-centered">Customs Gallery</h1>
          <h2 className="subtitle has-text-centered">
            Check out some of our past orders below
          </h2>

          <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
            <figure className="image" style={{ width: "100%", marginRight: "10px", marginTop: "10px" }}>
              <img src="/gallery/glass1.jpg" alt="Glass 1" />
            </figure>

            <figure className="image" style={{ width: "100%", marginRight: "10px", marginTop: "10px" }}>
              <img src="/gallery/glass2.jpg" alt="Glass 2" />
            </figure>

            <figure className="image" style={{ width: "100%", marginRight: "10px", marginTop: "10px" }}>
              <img src="/gallery/glass3.jpg" alt="Glass 3" />
            </figure>

            <figure className="image" style={{ width: "100%", marginRight: "10px", marginTop: "10px" }}>
              <img src="/gallery/glass4.jpg" alt="Glass 4" />
            </figure>

            <figure className="image" style={{ width: "100%", marginRight: "10px", marginTop: "10px" }}>
              <img src="/gallery/glass5.jpg" alt="Glass 5" />
            </figure>
          </div>
        </div>
      </section>
    </div>
  );
}