import Axios from "axios";

export const getProducts = async () => {
  const productsUrl = "/api/products";
  const response = await Axios.get(productsUrl);

  console.log("Get products: ", response);

  return response.data;
};

export const getProduct = async (id: string) => {
  const productUrl = `/api/product/${id}`;
  const response = await Axios.get(productUrl);

  console.log("Get product: ", id, response);

  return response.data;
};

export const getPrices = async () => {
  const pricesUrl = "/api/prices";
  const response = await Axios.get(pricesUrl);

  console.log("Get products: ", response);

  return response.data;
};

export interface ILineItem {
  price: string;
  quantity: number;
}

export const stripeCheckout = async (lineItems: ILineItem[]) => {
  const checkoutUrl = "/api/checkout";
  const response = await Axios.post(checkoutUrl, {
    line_items: lineItems,
  });

  const { sessionId } = response.data;
  console.log('Got session ID from checkout service: ', sessionId);

  return sessionId;
}
