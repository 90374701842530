import { observer } from 'mobx-react-lite';
import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { ILineItem, stripeCheckout } from '../services/stripe';
import ICartProduct from '../models/ICartProduct';
import { formatPrice } from '../utils';

const stripeKey =
  "pk_test_51HmK50B4iIlYRDim7mxD2JFVp9nweWp4TgB1DuyJohYOosCg08UThbMJI7KkzQwC99uTX5zN3HX3o1dRMwB7ouKo00zxjQwY3l";

const stripePromise = loadStripe(stripeKey);

function LineItem(product: ICartProduct) {
  return (
    <div className="card" style={{ display: "flex", flexDirection: "column", padding: "10px", marginBottom: "10px" }}>
      <p className="subtitle">Product - <b>{product.name}</b></p>
      <p className="subtitle">Quantity - {product.quantity}</p>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="subtitle">Price - {formatPrice(product.price.unit_amount)}</p>
        <p className="subtitle">Total - {formatPrice(product.price.unit_amount * product.quantity)}</p>
      </div>
    </div>
  )
}

export interface CartPageProps {
  products: ICartProduct[],
}

export function CartPage(props: CartPageProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { products } = props;
  const cartEmpty = !products.length;

  const grandTotal = products.reduce((a, c) => {
    return a + c.price.unit_amount * c.quantity;
  }, 0);

  async function checkout() {
    setIsLoading(true);

    const lineItems: ILineItem[] = products.map((product: ICartProduct) => ({
      price: product.price.id,
      quantity: product.quantity,
    }));

    const sessionId = await stripeCheckout(lineItems);
    console.log('Got session id form service: ', sessionId);

    const stripe = await stripePromise;
    stripe?.redirectToCheckout({ sessionId });
  }

  return (
    <section className="section">
      <div className="container" style={{ display: "flex", flexDirection: "column" }}>
        <h1 className="title has-text-centered">Your Cart</h1>

        {products.map(LineItem)}

        <p className="title">
          Grand Total - ${formatPrice(grandTotal)}
        </p>

        <button disabled={isLoading || cartEmpty} onClick={checkout} className="button is-primary">
          {!isLoading && "Checkout"}
          {isLoading && "Please wait..."}
        </button>
      </div>
    </section>
  )
}

export default observer(CartPage); 