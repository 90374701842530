import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from "react-toastify";

import { useStore } from '../stores/hooks';
import IProduct from '../models/IProduct';
import { formatPrice } from '../utils';

const notify = () => toast.info('🚀 Added to cart');


export function Product(props: { product: IProduct }) {
  const cartStore = useStore("cartStore");

  const [waitingForCart, setWaitingForCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [imageUrls, setImageUrls] = useState('');

  const { product } = props;

  function handleDecrement() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  function addToCart() {
    setWaitingForCart(true);
    cartStore.addToCart(product, quantity, imageUrls);
    setWaitingForCart(false);
    notify();
  }

  return (<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <h3 className="title has-text-centered">{product.name}</h3>
    <img src={product.images[0]} style={{ width: "200px", height: "200px" }} alt="Product" />
    <p className="subtitle has-text-centered">Price: ${formatPrice(product.price.unit_amount)}</p>

    <div className="container" style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", margin: "10px 0" }}>
        <p className="subtitle">Quantity</p>
        <p className="subtitle">Total: ${formatPrice(product.price.unit_amount) * quantity}</p>
      </div>

      <div>
        <input style={{ width: "auto" }} className="input is-primary" type="text" pattern="[0-9]*"
          onChange={(e) => setQuantity(Number.parseInt(e.target.value))} value={quantity} />

        <button className="button" onClick={handleDecrement}>-</button>
        <button className="button" onClick={() => setQuantity(quantity + 1)}>+</button>
      </div>

      <p className="subtitle" style={{ margin: "10px 0" }}>Custom Image Urls</p>
      <textarea className="textarea" value={imageUrls} onChange={(e) => setImageUrls(e.target.value)} />

      <button disabled={waitingForCart} onClick={addToCart} style={{ marginTop: "10px" }} className="button is-primary">
        {waitingForCart && "Loading..."}
        {!waitingForCart && "Add to Cart"}
      </button>
    </div>
  </div>)
}

export default observer(Product);