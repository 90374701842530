import React from 'react';
import { Link } from 'react-router-dom';

import { FaShoppingCart } from 'react-icons/fa';

import './Navbar.css';

function Navbar() {
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        {/* <a className="navbar-item" href="/">
          <img src="/logo/logo_transparent.png" width="200px" height="100px" alt="Logo"/>
        </a> */}

        <span role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="main-nav">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>
      </div>

      <div id="main-nav" className="navbar-menu">
        <div className="navbar-start">
          <Link className="navbar-item" to="/">
            Home
          </Link>

          <Link className="navbar-item" to="/shop">
            Shop
          </Link>

          <Link className="navbar-item" to="/gallery">
            Gallery
          </Link>
        </div>

        <div className="navbar-end">
          <div className="buttons" style={{ width: "100%", display: "flex" }}>
            <Link className="navbar-item" to="/cart" style={{ flex: "1" }}>
              <FaShoppingCart />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
