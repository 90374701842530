import React from "react";
import { Link } from "react-router-dom";
import IProduct from '../models/IProduct';

export const Product = (data: IProduct, index: number) => {
  return (
    <div style={{ marginTop: "10px" }} key={`product-card-${index}`}>
      <Link to={`/product/${data.id}`}>
        <div
          className="card"
          key={`product-${index}`}
          style={{ cursor: "pointer" }}
        >
          <div className="card-image">
            <img
              alt="Product"
              src={data.images[0]}
              style={{
                width: "200px",
                height: "200px",
              }}
            />
          </div>

          <div className="card-content">
            <div className="content">
              <p className="title has-text-centered">{data.name}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Product;
