import { makeAutoObservable } from "mobx";
import { createContext } from 'react';
import IProduct from '../models/IProduct';

export class ProductStore {
  products: IProduct[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getProducts() {
    console.log('Getting products');
    const response = await fetch('/api/products');
    const products = await response.json();
    console.log('Updating with: ', products);
    this.products = products;
  }
}

export const ProductContext = createContext<ProductStore>(new ProductStore());