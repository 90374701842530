import React from "react";
import { observer } from 'mobx-react-lite';

import Product from '../components/ProductCard';
import IProduct from '../models/IProduct';

export interface ShopPageProps {
  products: IProduct[];
}

function ShopPage(props: ShopPageProps) {
  const { products } = props;
  console.log('Products? ', products);

  return (
    <section className="section">
      <div
        className="welcome-container"
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div className="container">
          <h1 className="title has-text-centered">Shop!</h1>
          <p className="subtitle has-text-centered">
            Welcome to the PTSK custom shop.
          </p>

          <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" }}>
            {products.map(Product)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default observer(ShopPage);
