import React from "react";
import { CartStore } from './CartStore';
import { ProductStore } from './ProductStore';

export const stores = Object.freeze({
  productStore: new ProductStore(),
  cartStore: new CartStore(), 
});

stores.productStore.getProducts();

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;