import React from "react";
import { Link } from 'react-router-dom';

export default function Welcome() {
  return (
    <div
      className="welcome-container"
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <section className="section">
        <div className="container" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img src="/logo/logo-ptsk.jpg" style={{ maxWidth: "80%" }} alt="Glass 1" />

          <h1 className="title has-text-centered">Welcome to PTSK!</h1>
          <h2 className="subtitle has-text-centered">
            We are a family owned and operated craft shop.
          </h2>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            Check out the

            <Link to="/shop" className="is-fullwidth">
              <button className="button is-primary" style={{ margin: "0 10px" }}>
                shop
              </button>
            </Link>

            to see our current products.
          </div>
        </div>
      </section>
    </div>
  );
}
