import React, { Suspense } from "react";

import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from "./components/Navbar";
import WelcomPage from "./pages/WelcomePage";
import ShopPage from "./pages/ShopPage";
import ProductPage from "./pages/ProductPage";
import GalleryPage from "./pages/GalleryPage";
import CartPage from './pages/CartPage';

import "bulma/css/bulma.css";
import "./App.css";
import { useStore } from './stores/hooks';

function App() {
  const productStore = useStore("productStore");
  const cartStore = useStore("cartStore");

  return (
    <Suspense fallback="Loading UI">
      <div className="App">
        <ToastContainer />

        <Router>
          <Navbar />

          <Switch>
            <Route path="/gallery">
              <GalleryPage />
            </Route>

            <Route path="/shop">
              <ShopPage products={productStore.products} />
            </Route>

            <Route path="/cart">
              <CartPage products={cartStore.cart} />
            </Route>

            <Route
              path="/product/:id"
              render={(props) => <ProductPage {...props} />}
            />

            <Route path="/">
              <WelcomPage />
            </Route>
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
}

export default observer(App);